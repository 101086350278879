$filter-bg-color: rgba(255, 255, 255, 0.25);

.search-form {
	input[type=search] {
		border-radius: 100px;
	}

	.btn:hover {
		border-color: transparent;
	}
}


.object-filter {

	.facetwp-type-search {
		margin-bottom: 0.5rem !important;
		.facetwp-search {
			min-width: 10rem;
			font-size: 0.875rem;
			padding: 0.375rem 1.875rem 0.375rem 0.5rem;
			border: 1px solid $input-border-color;
			border-radius: 0;
			color: #777;
			background-color: rgba(255, 255, 255, 0.25);
		}
	}

	.facetwp-type-autocomplete {
		width: 100%;
		margin-bottom: 0.5rem !important;
		.facetwp-autocomplete {
			display: inline-block;
			font-size: 0.875rem;
			padding: 0.375rem 0.5rem;
			border: 1px solid $input-border-color;
			border-radius: 0;
			color: #777;
			background-color: rgba(255, 255, 255, 0.25);
			width: calc(100% - 42px);

		}
		.fcomplete-hidden {
			display: none;
		}
		.facetwp-autocomplete-update {
			display: inline-block;
			float: right;
			width: 36px;
			height: 30px;
			font-size: 0.875rem;
			font-weight: 400;
			color: #777;
			border: 1px solid $input-border-color;
			padding: 1px 5px;
			text-align: center;
		}
	}

	/*
	.sidebar-search {
		position: relative;
		margin-bottom: 1rem;

		input[type="search"] {
			font-size: 0.875rem;
			padding-left: 2rem;
			padding-right: 0.25rem;
			border-radius: 0;
			color: #777;
			background-color: rgba(255, 255, 255, 0.25)
		}

		button[type="submit"] {
			position: absolute;
			top: 50%;
			left: 0;
			background-color: transparent;
			padding: 0 0.75rem;
			transform: translateY(-50%);
		}
	}
	*/

	.accordion-item {

		background-color: $filter-bg-color;

		.accordion-header {
			.accordion-button {
				color: $brand-blue;
				background: transparent;
			}
		}

		.accordion-body {
			position: relative;
		}

		.accordion-body.initialContent {
			overflow: hidden;
			height: auto;
		}

		.accordion-body.hideContent {
			overflow: hidden;
			height: auto;
		}

		.accordion-body.showContent {
			height: auto;
			overflow: hidden;
		}

	}


	.js-show-more {
		position: sticky;
		bottom: 0;
		left: 0;
		right: 0;

		display: block;
		width: 100%;
		padding: 0.5rem 1.25rem;
		color: #aaa;
		font-size: 0.875rem;
		background-color: $pure-white;
		text-decoration: none;

		opacity: 0;
		transition: opacity 50ms ease-in-out;

		&:hover {
			color: $brand-orange;
		}

		i {
			margin: 0 0.5rem 0 0.125rem;
		}
	}

	.js-show-more.open {
		background-color: $pure-white;
	}

	.js-show-more.closed {
		background-color: $pure-white;
	}

}

.search,
.landing,
.review {
	.object-filter {
		.accordion-item {
			.accordion-body.initialContent {
				max-height: 11rem;
			}

			.accordion-body.hideContent {
				max-height: 11rem;
			}
		}
	}
}


.delete-filter {
	height: 2rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	font-size: 0.875rem;
	text-align: right;

	a {
		color: #aaa;
		text-decoration: none;
	}
}


.object-filter {

	.form-check {

		input[type=checkbox] {
			border-radius: 0;
		}

		&.form-check-child {
			padding-left: 2.5rem;
		}
	}

	.filter-more {
		i {
			color: $body-text-color;
		}
	}

	.filter-children {
		display: none;

		&.open {
			display: block;
		}

		&.closed {
			display: none;
		}
	}

	.taxonomy-tree-widget {
		margin-bottom: 1rem;
		padding: 1rem 1.25rem;
		background-color: $filter-bg-color;
		border: solid 1px rgb(222, 226, 230);

		h2 {
			font-size: 1rem;
			color: rgb(33, 37, 41)
		}

		ul {
			list-style: none;
			margin: 0;
			padding-left: 0.75rem;

			li {
				a {
					text-decoration: none;
					color: $body-text-color;

					&:hover {
						text-decoration: underline;
					}
				}

				&.active {
					font-weight: 700;
					color: $brand-orange;
				}
			}
		}
	}
}

