/* navbar */
.navbar {
	padding: 0;

	.container-fluid {
		padding-left: 0.25rem;
		padding-right: 0.25rem;
	}

	.navbar-left {
		width: 7.5rem;
		height: 3.75rem;
	}

	.backtolist {
		height: 3.75rem;
		padding: 0.875rem;

		&__button {
			display: block;
			width: 1.875rem;
			height: 1.875rem;
			padding-top: 0.1875rem;
			padding-right: 0.0625rem;
			border: solid 1px rgba($pure-white, 0.8);
			border-radius: 50%;
			text-align: center;

			i {
				color: rgba($pure-white, 0.8);
			}
		}
	}

}

/* toggler */
.navbar-expand-lg .navbar-toggler {
	display: block;
	border: none;
	padding-top: 0.875rem;
	padding-bottom: 0.875rem;

	&:focus,
	&:active {
		outline: none;
		box-shadow: none;
	}
}

/* main nav */
.navbar-nav {
	margin-bottom: 0;

	&.navbar-nav-main {
		margin-right: auto;
	}

	.nav-link {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	&.navbar-nav-icons {
		flex-direction: row;
		margin-left: auto;

		.nav-link {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			line-height: 1.5;

			i {
				font-size: 1.5rem;
				line-height: 1.25;
				height: 1.875rem;
			}

		}
	}
}

/* dropdown menu */
.dropdown-menu {
	border-radius: 0;
	border: none;
}

.dropdown-toggle::after {
	font-family: "Font Awesome 5 Pro";
	margin-left: 0.255em;
	vertical-align: 0;
	content: "\f107";
	color: inherit;
	font-size: 0.8125rem;
	font-weight: 400;
	line-height: 1;
	height: 0.8125rem;
	border: none;
}

/* colors */
.navbar-dark {

	background-color: $brand-dark-blue !important;

	.navbar-nav {
		.nav-link {
			color: rgba($pure-white, 0.8);

			&:focus {
				color: rgba($pure-white, 1);
			}

			&:hover {
				color: rgba($pure-white, 1);
			}
		}

		.dropdown-menu {
			background-color: $pure-white;
			box-shadow: 0px 2px 5px 0px rgba(190, 190, 190, 0.6);

			.dropdown-item {

				color: $brand-dark-blue;

				&:focus {
					color: $brand-orange;
					background: none;
				}

				&:hover {
					color: $brand-orange;
					background: none;
				}
			}
		}
	}
}

@media (min-width: 992px) {

	.navbar-expand-lg {
		flex-wrap: wrap;
	}

	// margins & padding
	.navbar-nav {
		.nav-link {
			padding-top: 1.5625rem;
			padding-bottom: 1.875rem;
			line-height: 1.875;
		}
	}

	// border color on hover
	.navbar-nav .nav-link {
		border-bottom: 4px solid transparent;

		&:hover {
			border-bottom: 4px solid $brand-scnd-blue-light;
		}
	}

	.nav-link.dropdown-toggle.show {
		border-bottom: 4px solid $brand-scnd-blue-light;
	}

	.dropdown-menu[data-bs-popper] {
		margin-top: 0;
		transition: all 0.3s ease-out;
	}


	// shrink navbar on scroll
	.navbar {
		&.navbar-shrink {
			.navbar-nav .nav-link {
				padding: 1.25rem 1rem 0.875rem;
				transition: padding 0.3s ease-out;
			}

			&.navbar-scrolled {
				.navbar-nav .nav-link {
					padding-top: 1rem;
					padding-bottom: 0.625rem;
				}
			}
		}
	}
}


/* call to action */
.js-joincta {
	display: none;
	width: 100%;

	a {
		display: block;
		padding: 0.5rem 0;
		color: rgba(255, 255, 255, 0.8);
		text-decoration: none;
		font-size: 1rem;
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 1px;
		background-color: rgb(243, 115, 71);
		text-align: center;

		&:hover {
			color: rgba(255, 255, 255, 1);
		}
	}

}

/* cart icon */
.th-cart-contents {
	position: relative;
}

.cart-contents-count {
	position: absolute;
	top: 5px;
	right: -2px;
	font-size: 11px;
	font-weight: 700;
	width: 20px;
	height: 20px;
	background-color: $pure-white;
	border: 1px solid $brand-orange;
	color: $brand-orange;
	border-radius: 50%;
	text-align: center;
	line-height: 18px;
}

@media(min-width:992px) {
	.cart-contents-count {
		top: 15px;
		right: 5px;
	}
}



