
body {
	font-family: $body-text-font;
	line-height: $body-line-height;
	font-size: $body-font-size;
	text-rendering: geometricPrecision;

	color: $body-text-color;
	background: $body-bg-color;

	overflow-x: hidden;
	overscroll-behavior-y: none;
}

img {
	max-width: 100%;
	height: auto;
}

.container-fluid {
	max-width: 1140px;
}

.content-main {
	min-height: 18.75rem;
}
:focus-visible {
	outline: none;
}

.form-control:focus {
	box-shadow: none;
}

.scrollbar {
	width: 500px;
	height: 150px;
	overflow-y: scroll;
	scrollbar-color: grey transparent;
	scrollbar-width: thin;
}

.scrollbar::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 5px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: transparent;
}

.scrollbar::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.scrollbar::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	box-shadow: inset 0 0 5px rgba(0,0,0,.3);
	background-color: grey;
}

@for $h from 1 through 19 {
	@include viewport-height($h);
}


.divider {
	width: 100%;
	margin: 3rem 0;
	height: 0;
	border-bottom: solid 1px rgba($brand-blue, 0.25);
}



