/*
.author-box {
	margin: 2rem 0;

	border: solid 1px rgba(85, 130, 150, 0.5);
	background-color: rgba(243, 115, 71, 0.03);
}
.content-box {
	margin: 2rem 0;

	border: solid 1px rgba(243, 115, 71, 0.25);
	background-color: rgba(243, 115, 71, 0.03);
}
*/

.content-box {
	margin: 2.5rem 0;
	padding: 1rem;
	border: none !important;
	background-color: rgba(197, 231, 250, 0.6) !important;
}
.author-box {
	margin: 2.5rem 0;
	padding: 1rem;
	border: none;
	border-left: solid 5px rgb(104, 89, 117) !important;
	background-color: rgba(120, 70, 180, 0.15) !important;
}
