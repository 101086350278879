.pagination-container {
	margin-top: 3rem;
	> .col {
		display: flex;
		justify-content: center;
	}
}

/* facetwp */
.pagination {
	.facetwp-pager {
		.facetwp-page {
			position: relative;
			display: inline-block;
			margin: 0;
			padding: 0.375rem 0.75rem;
			font-size: 1rem;
			font-weight: 400;
			color: $brand-blue;
			text-decoration: none;
			background-color: $pure-white;
			border: solid 1px $pagination-border-color;
			cursor: pointer;

			transition: all 0.15s ease-in-out;

			&:not(:first-child) {
				margin-left: -1px;
			}

			&:hover {
				z-index: 2;
				color: $pagination-hover-color;
				text-decoration: none;
				background-color: $pagination-hover-bg;
				border-color: $pagination-hover-border-color;
			}

			&:focus {
				z-index: 3;
				color: $pagination-focus-color;
				background-color: $pagination-focus-bg;
				outline: $pagination-focus-outline;
				box-shadow: $pagination-focus-box-shadow;
			}

			&.active,
			.active > & {
				font-weight: 400 !important;
				z-index: 3;
				color: $pagination-active-color;
				background-color: $pagination-active-bg;
				border-color: $pagination-active-border-color;
			}

			&.dots,
			&.disabled,
			.disabled > & {
				color:$pagination-disabled-color;
				pointer-events: none;
				background-color: $pagination-disabled-bg;
				border-color: $pagination-disabled-border-color;
			}
		}
	}
}
