.paytag {

	position: absolute;
	top: 0;
	left: 0;
	right: auto;
	padding: 0.125rem 0.375rem;
	font-size: 0.75rem;
	font-weight: 600;

	color: $pure-white;
	opacity: 1;

	&--small {
		top: 0;
		left: 0;
		right: auto;
		padding: 3px 5px;
		font-size: 11px;
		line-height: 1;
		opacity: 0.9;
	}

	&--basic {
		background-color: $brand-paywall-basic;
	}

	&--premium {
		background-color: $brand-paywall-premium;
	}

	&--none {
		display: none;
	}
}

.card-link {
	position: relative;
	display: block;
	text-decoration: none;
	color: $body-text-color;

	&:hover {
		color: $body-text-color;
	}

	h3 {
		margin-top: 0.25rem;
		font-size: 1rem;
	}

	&:not(.has-image) {
		.paytag {
			top: 0;
			right: 0;
			left: auto;
		}
	}

	.image-container {
		position: relative;
	}

	.card-link__tags {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap !important;
		list-style: none;
		margin: 0 0 1rem 0;
		padding: 0;

		.card-link__tag {
			display: inline-block;
			margin: 0 0 0.375rem 0;
			padding: 0 0.375rem;
			font-size: 0.875rem;
			line-height:1;
			color: rgba(120,130,150);
			border-right: solid 1px rgba($brand-blue, 0.5);

		}
	}
}

@media(min-width: 576px) {

	.card-link {
		.card-link__tags {
			.card-link__tag {
				margin: 0 0.375rem 0.375rem 0;
				padding: 0.1875rem 0.5rem;
				border: solid 1px rgba($brand-blue, 0.15);
			}
		}
	}
}

@media(min-width: 768px) {

	.card-link {

		h3 {
			font-size: 1.125rem;
		}

		&:hover {
			background: $link-hover-bg;
		}

	}

}

.image-link {
	position: relative;
	display: block;

	&.zoom {
		overflow: hidden;

		img.zoom-img {
			width: 100%;
			height: 100%;
			transition: transform 250ms ease-in-out 0s;
		}

		&:hover img.zoom-img {
			transform: scale(1.02);
		}

	}

	.image-link__meta {
		display: flex;
		align-items: center;
		color: rgba(255, 255, 255, 0.8);
		transition: color 250ms ease-in-out;

		.theme-icon--inline {
			width: 15px;
			margin-right: 5px;
		}
	}

	.image-link__title {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		padding: 20px;
		background-image: linear-gradient(rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.7));
	}

	h3 {
		line-height: 1.6;
		overflow-wrap: break-word;
		color: $pure-white;
	}

	&--large {
		h3 {
			font-size: 1.25rem !important;
			line-height: 1.3 !important;
			margin-bottom: 0.5rem;
		}

		.image-link__meta {
			font-size: 13px;
			line-height: 1.2;
		}

		.image-link__title {
			padding: 15px;
		}
	}

	&--small {
		h3 {
			font-size: 1.25rem !important;
			line-height: 1.3 !important;
			margin-bottom: 0.5rem;
		}

		.image-link__meta {
			font-size: 13px;
			line-height: 1.2;
		}

		.image-link__title {
			padding: 15px;
		}
	}

}

@media (min-width: 480px) {
	.image-link {
		&--large {
			h3 {
				font-size: 1.5rem !important;
				line-height: 1.4 !important;
			}

			.image-link__meta {
				font-size: 14px;
			}

			.image-link__title {
				padding: 20px;
			}
		}

		&--small {
			h3 {
				font-size: 1.5rem !important;
				line-height: 1.4 !important;
			}

			.image-link__meta {
				font-size: 14px;
			}

			.image-link__title {
				padding: 20px;
			}
		}
	}
}

@media (min-width: 576px) {
	.image-link {
		&--large {
			h3 {
				font-size: 1.5rem !important;
				line-height: 1.6 !important;
			}

			.image-link__meta {
				font-size: 14px;
			}

			.image-link__title {
				padding: 20px;
			}
		}

		&--small {
			h3 {
				font-size: 0.875rem !important;
				line-height: 1.4 !important;
			}

			.image-link__meta {
				font-size: 12px;
			}

			.image-link__title {
				padding: 12px;
			}
		}
	}
}

.post-list {
	margin-botom: 3rem;

	&__item {
		padding: 1rem 0;
		border-bottom: solid 1px rgba($brand-blue, 0.15);
	}

	&__item-thumb {
		position: relative;
	}

	&__item-content {
		h3 {
			margin-top: 0;
			margin-bottom: 0.375rem;
			color: $brand-dark-blue;
		}

		&--meta {
			display: flex;
			align-items: center;
			font-size: 13px;
			color: $brand-blue;
			margin-bottom: 0.375rem;

			img {
				width: 15px;
				margin-right: 5px;
			}
		}

		&--lead {
			height: 44px;
			overflow: hidden;
		}

		p {
			margin: 0;
			font-size: 0.875rem;
		}
	}

}

